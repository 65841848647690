<script setup lang="ts">
import DropDownItems from '~/components/organisms/drop-down/DropDownItems.vue'
import type { DropdownClose } from '~/src/types/Dropdown'

interface Props {
  position?: 'left' | 'right'
  itemsContainerClass?: string
  scrollable?: boolean
  borderless?: boolean
  teleport?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  position: 'left',
  scrollable: true,
  borderless: false,
  teleport: false,
})

const toggled = ref(false)
const onHover = ref(false)

const onClick = () => {
  toggled.value = !toggled.value
}

const close: DropdownClose = () => {
  toggled.value = false
}

const slotRef = ref<HTMLDivElement | null>(null)
const { top, left, height, width } = useElementBounding(slotRef)

const isSlotVisible = useElementVisibility(slotRef)
watch(isSlotVisible, (value) => {
  if (!value) {
    toggled.value = false
  }
})

const contentRef = ref<HTMLDivElement | null>(null)
onClickOutside(contentRef, () => {
  if (!onHover.value) {
    toggled.value = false
  }
})

const leftPosition = computed(() => props.position === 'right' ? left.value : left.value + width.value)
const topPosition = computed(() => top.value + height.value)
</script>

<template>
  <div class="relative">
    <div
      ref="slotRef"
      @mouseenter="onHover = true"
      @mouseleave="onHover = false"
    >
      <slot
        :on-click="onClick"
        :toggled="toggled"
      />
    </div>
    <template v-if="teleport">
      <Teleport to="body">
        <div
          ref="contentRef"
          class="fixed z-40"
          :style="{ left: `${leftPosition}px`, top: `${topPosition}px` }"
        >
          <drop-down-items
            v-if="toggled"
            :position="position"
            :scrollable="scrollable"
            :borderless="borderless"
            :items-container-class="itemsContainerClass"
          >
            <slot
              name="items"
              :close="close"
            />
          </drop-down-items>
        </div>
      </Teleport>
    </template>
    <template v-else>
      <div ref="contentRef">
        <drop-down-items
          v-if="toggled"
          :position="position"
          :scrollable="scrollable"
          :borderless="borderless"
          :items-container-class="itemsContainerClass"
        >
          <slot
            name="items"
            :close="close"
          />
        </drop-down-items>
      </div>
    </template>
  </div>
</template>

<style scoped lang="postcss">

</style>
